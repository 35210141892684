import { Night, ClubPlayer, Game } from "@mixitone/models";
import MixitoneController from "controllers/MixitoneController";
import { withStateToggle } from "@mixitone/util";
import * as reporting from "lib/reporting";
import { List } from "@mixitone/oom";

interface Props {
  clubId: string;
  id: string;
}

interface State {
  loading: boolean;
  night: Night;
  newPlayers: List<ClubPlayer>;
  totalGames: number;
  totalSets: number;
}

export class SessionDrawerController extends MixitoneController<State, Props> {
  override get initialState(): State {
    return {
      loading: false,
      night: new Night({}),
      newPlayers: new List(),
      totalGames: 0,
      totalSets: 0,
    };
  }

  async initialize(props: Props) {
    await this.changeProps(props);
  }

  @withStateToggle("loading")
  async changeProps(newProps: Props) {
    if (this.state.night?.id === newProps.id) return;

    const night = await Night.query().preload("players").find(newProps.id);
    if (!night) {
      console.error("Session not found", newProps.id);
      return;
    }

    this.state.night = night;

    // Get game counts
    const gameSets = night.game_sets.query;
    this.state.totalSets = await gameSets.count();
    this.state.totalGames = await Game.query().joins(gameSets).count();

    const { clubPlayerIds } = await reporting.sessionNewPlayers({ sessionId: newProps.id });
    if (clubPlayerIds.length > 0) {
      const newPlayers = await ClubPlayer.query()
        .in("id", clubPlayerIds)
        .preload("group")
        .orderBy("name")
        .all();
      this.state.newPlayers = new List(...newPlayers);
    }
  }
}
