import { Drawer } from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";

type DrawerProps = React.ComponentProps<typeof Drawer>;

const RoutedDrawer = ApplicationView((props: Omit<DrawerProps, "onClose">) => {
  const routeController = window.routeController;
  if (!routeController) {
    throw new Error("RouteController not found");
  }

  const handleClose = () => {
    routeController.actionCloseModal({ viewTransition: false });
  };

  return <Drawer {...props} onClose={handleClose} />;
});
export { RoutedDrawer };
