import { Club, Night } from "@mixitone/models";
import ClubPlayer from "@mixitone/models/ClubPlayer";
import Game from "@mixitone/models/Game";
import GameSet from "@mixitone/models/GameSet";
import Group from "@mixitone/models/Group";
import Player from "@mixitone/models/Player";
import { List } from "@mixitone/oom";
import { isNil, isPresent, withStateToggle } from "@mixitone/util";
import * as Sentry from "@sentry/react";
import MixitoneController from "controllers/MixitoneController";

interface State {
  editing: boolean;
  night: Night;
  player?: Player;
  club: Club;
  players: List<Player>;
  clubPlayer: ClubPlayer;
  clubPlayers: List<ClubPlayer>;
  pairedPlayer: Player | null;
  setGames: { gameSet: GameSet; game: Game; players: Player[] }[];
  loading: boolean;
  toggling: boolean;
  updating: Set<"group" | "notes">;
  errors: { name?: string };
}

interface Props {
  token: string;
  clubId: string;
  id: string;
}

export class PlayerDrawerController extends MixitoneController<State, Props> {
  override get initialState() {
    return {
      editing: false,
      setGames: [],
      loading: true,
      toggling: false,
      updating: new Set<"group" | "notes">(),
      players: new List<Player>(),
      clubPlayer: new ClubPlayer({}),
      clubPlayers: new List<ClubPlayer>(),
      pairedPlayer: null,
      errors: {},
      night: new Night({}),
      club: new Club({}),
    };
  }

  @withStateToggle("loading")
  async changeProps({ token, clubId, id }: Props) {
    if (this.state.clubPlayer?.id === id) return;

    this.state.editing = false;
    this.state.toggling = false;
    this.state.updating.clear();

    const [night, club] = await Promise.all([
      Night.query().preload("players", "game_sets").find(token),
      Club.query().preload("club_players", "groups").find(clubId),
    ]);
    this.state.night = night;
    this.state.club = club;
    this.state.club.club_players.subscribe({ update: true, insert: true });
    this.state.clubPlayers = new List(...this.state.club.club_players);

    this.state.clubPlayers.forEach((cp) => {
      const group = this.state.club.groups.findById(cp.group_id!);
      if (!group) {
        console.error("Group not found for club player");
        Sentry.captureException(new Error("Group not found for club player when loading drawer"), {
          extra: {
            clubPlayerId: cp.id,
            groupId: cp.group_id,
          },
        });
        return;
      }
      cp.group.set(group);
    });

    const clubPlayer = this.state.clubPlayers.findById(id);
    if (!clubPlayer) {
      Sentry.captureException(new Error("Club player not found when loading drawer"));
      console.error("Club player not found when loading drawer");
      this.actionAddMessage("Player not found", "error");
      return;
    }

    this.state.clubPlayer = clubPlayer;

    await this.loadPlayers();
    await this.loadPlayer();
  }

  get group() {
    return this.state.clubPlayer.group;
  }

  get pairedClubPlayer() {
    return this.state.clubPlayers.find((cp) => cp.id === this.state.pairedPlayer?.club_player_id);
  }

  async loadPlayers() {
    const players = this.state.night.players;
    players.forEach((player) => {
      const playerClubPlayer = this.state.clubPlayers.findById(player.club_player_id!);
      if (playerClubPlayer) {
        player.clubPlayer.set(playerClubPlayer);
      }
    });
    this.state.players = new List(...players.filter((p) => isPresent(p.clubPlayer)));
  }

  async loadPlayer() {
    const player = this.state.players.find((p) => p.club_player_id === this.state.clubPlayer.id);
    this.state.player = player;
    if (!player) return;

    const clubPlayer = this.state.clubPlayer;

    if (isNil(clubPlayer)) {
      console.error("Player does not have a club player");
      Sentry.captureException(new Error("Player does not have a club player when loading drawer"));
      return;
    }

    await this.state.clubPlayer.notes.load();

    if (player.partner_id) {
      this.state.pairedPlayer = this.state.players.find((p) => p.id === player.partner_id) || null;
    } else {
      this.state.pairedPlayer = null;
    }

    this.state.setGames = this.state.night.game_sets
      .filter((gs) => gs.started)
      .reduce(
        (acc, gs) => {
          const game = gs.games.find((g) => g.hasPlayer(player.id!));
          if (!game) return acc;

          acc.push({
            gameSet: gs,
            game,
            players: game.playerIds
              .reduce((acc, id) => {
                if (!id) return acc;
                const player = this.state.players.findById(id);
                if (!player) return acc;
                if (player.id === this.state.player?.id) return acc;
                acc.push(player);
                return acc;
              }, [] as Player[])
              .toSorted((a, b) => a.name!.localeCompare(b.name!)),
          });

          return acc;
        },
        [] as State["setGames"],
      );
  }

  async actionMoveToGroup(group: Group) {
    if (!this.state.clubPlayer) return;
    this.state.clubPlayer.group = group;
    await this.state.clubPlayer.save();
  }

  async actionSave() {
    if (!this.state.clubPlayer) return;
    if (!this.state.player) return;

    await this.state.clubPlayer.save();
    this.state.player.name = this.state.clubPlayer.name;
    await this.state.player.save();
  }

  actionEditPlayer(edit: boolean) {
    this.state.editing = edit;
  }

  async actionSetPairedPlayer(clubPlayer: ClubPlayer | null) {
    const player = this.state.players.find((p) => p.club_player_id === clubPlayer?.id) || null;
    this.state.pairedPlayer = player;

    if (player) {
      await this.state.night.pairPlayers(this.state.player!, player);
    } else {
      await this.state.night.unpairPlayer(this.state.player!);
    }
  }

  @withStateToggle("toggling")
  async actionJoin() {
    await this.state.night.togglePlayer(this.state.clubPlayer);
    await this.loadPlayers();
    await this.loadPlayer();
  }

  @withStateToggle("toggling")
  async actionRetire() {
    if (!this.state.player) return;
    await this.state.night.togglePlayer(this.state.clubPlayer);
    await this.loadPlayers();
    this.state.player = undefined;
  }

  @withStateToggle("loading")
  async actionSwitchDrawer(id: string) {
    const cp = this.state.clubPlayers.findById(id);
    if (!cp) return;
    this.state.clubPlayer = cp;
    await this.loadPlayer();
  }
}
