import {
  AssignableModelFields,
  Model,
  ModelAttributes,
  collection,
  model,
  modelConfig,
  reference,
} from "@mixitone/oom";
import { indexBy, isNil, isPresent } from "@mixitone/util";
import dayjs from "dayjs";
import Account from "./Account";
import Club from "./Club";
import ClubPlayer from "./ClubPlayer";
import Game from "./Game";
import GameSet from "./GameSet";
import Player from "./Player";
import PQueue from "p-queue";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    auto_fill: {
      type: "boolean",
    },
    club_id: {
      type: "string",
    },
    courts: {
      type: "number",
    },
    date: {
      type: "datetime",
    },
    game_set_time: {
      type: "number",
    },
    is_deleted: {
      type: "boolean",
    },
    player_fee: {
      type: "number",
    },
    session_id: {
      type: "string",
    },
    token: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
    warm_up_started_at: {
      type: "datetime",
    },
    warm_up_stopped_at: {
      type: "datetime",
    },
    warm_up_time: {
      type: "number",
    },
  },
  {
    players: collection<Player>(() => Player, "night_id", { order: "name" }),
    game_sets: collection<GameSet>(() => GameSet as any, "night_id", {
      order: "index",
    }),
  },
  {
    club: reference<Club>(() => Club, "club_id"),
  },
);

interface Night extends ModelAttributes<typeof config> {}

@model("nights", config)
class Night extends Model<typeof config> {
  static async createAndSetup(attrs: AssignableModelFields<typeof config>): Promise<Night> {
    const night = new Night(attrs);
    if (!night.club_id) throw new Error("Must provide a club_id");
    night.date = new Date();
    night.account_id ||= Account.current!.id;
    night.courts ||= 5;
    await night.save();
    await night.ensureSets();

    if (isNil(night.token)) {
      night.token = night.id;
      await night.save();
    }

    return night;
  }

  static async findByToken(token: string) {
    const night = await this.query().findBy("token", token);
    return night ?? (await this.query().findBy("id", token));
  }

  get activePlayers() {
    return this.players.filter((player) => player.active);
  }

  get active() {
    if (this.is_deleted) return false;
    if (!this.date) return false;
    return dayjs(this.date).isAfter(dayjs().subtract(12, "hours"));
  }

  get expired(): boolean {
    if (!this.date) return true;
    return dayjs(this.date).isBefore(dayjs().subtract(2, "days"));
  }

  async createSet() {
    if (!this.persisted) throw new Error("night must be persisted");
    await this.game_sets.load();

    const index = this.game_sets.length;
    const set = await this.game_sets.create({
      index,
      started_at: undefined,
      stopped_at: undefined,
    });
    set.night.set(this);
    await set.ensureGames(this.courts!);
    return set;
  }

  async ensureSets() {
    await this.game_sets.load();
    const sets = this.game_sets;

    // Preload all the games into the sets
    const games = indexBy(
      await Game.query()
        .in(
          "game_set_id",
          sets.map((s) => s.id),
        )
        .orderBy("index")
        .all(),
      "game_set_id",
    );

    for (const set of sets) {
      set.games.set(games[set.id as string] || []);
    }

    if (sets.length === 0) {
      await this.createSet();
      await this.createSet();
      await this.createSet();
      await this.createSet();
    } else {
      await Promise.all(
        sets.map(async (s, index) => {
          s.index = index;
          await s.save();
          await s.ensureGames(this.courts as number);
        }),
      );
    }
  }

  async updateCourts(courts: number) {
    this.courts = courts;
    await this.save();
    await Promise.all(this.game_sets.map((set) => set.ensureGames(courts)));
  }

  get requiresPayment() {
    return !isNil(this.player_fee) && this.player_fee > 0;
  }

  get currentSetIndex() {
    return this.game_sets.findIndex((set, _idx) => {
      if (set.running) return true;
      if (!set.started) return true;
      return false;
    });
  }

  get currentSet(): GameSet | undefined {
    return this.game_sets[this.currentSetIndex];
  }

  get nextSet(): GameSet | undefined {
    return this.game_sets[this.currentSetIndex + 1];
  }

  setIndex(set: GameSet) {
    return this.game_sets.indexOf(set);
  }

  async addClubPlayer(clubPlayer: ClubPlayer) {
    if (this.players.find((p) => p.club_player_id === clubPlayer.id)) return;

    const player = await this.players.create({
      name: clubPlayer.name,
      club_player_id: clubPlayer.id,
      active: true,
      index: this.players.length,
    });
    player.clubPlayer.set(clubPlayer);

    if (this.warm_up_can_start) {
      await this.start_warm_up();
    }

    await this.autoFillNextSet();

    return player;
  }

  private _autoFillSetQueue = new PQueue({ concurrency: 1 });
  async autoFillNextSet() {
    if (!this.auto_fill) return;

    this._autoFillSetQueue.pause();
    this._autoFillSetQueue.clear();
    this._autoFillSetQueue.add(async () => {
      const setToFill = [this.currentSet, this.nextSet].find(
        (set) => set && !set.started && !set.user_modified,
      );

      if (setToFill) {
        setToFill.clear();
        await setToFill.fill();
        setToFill.clearUndo();
      } else {
        const setToFill = await this.createSet();
        await setToFill.fill();
        setToFill.clearUndo();
      }
    });
    this._autoFillSetQueue.start();

    return new Promise((resolve) => this._autoFillSetQueue.once("idle", resolve));
  }

  async retirePlayer(id: string) {
    const player = this.players.findById(id);
    if (!player) return;

    // Remove from future sets
    await Promise.all(
      this.game_sets
        .slice(this.currentSetIndex)
        .filter((set) => !set.started)
        .flatMap((set) => set.removePlayer(id)),
    );

    // Check if the player is not in any set
    const isInPreviousSets = this.game_sets.some((set) => set.hasPlayer(id));

    if (!isInPreviousSets) {
      // Delete the player
      await player.destroy();
      // this.players.removeById(id);
    } else {
      // Make inactive
      player.active = false;
      await player.save();
    }
  }

  async togglePlayerOn(clubPlayer: ClubPlayer) {
    const existing = this.players.filter((player) => player.club_player_id === clubPlayer.id);
    if (existing.length > 0) {
      for (const player of existing) {
        if (!player.active) {
          player.active = true;
          await player.save();
        }
      }
    } else {
      await this.addClubPlayer(clubPlayer);
    }
  }

  async togglePlayerOff(clubPlayer: ClubPlayer) {
    for (const player of this.activePlayers.filter((player) => player.club_player_id === clubPlayer.id)) {
      await this.retirePlayer(player.id!);
    }
  }

  hasPlayer(clubPlayer: ClubPlayer) {
    return this.activePlayers.some((player) => player.club_player_id === clubPlayer.id);
  }

  async togglePlayer(clubPlayer: ClubPlayer) {
    if (this.hasPlayer(clubPlayer)) {
      await this.togglePlayerOff(clubPlayer);
    } else {
      await this.togglePlayerOn(clubPlayer);
    }
  }

  async updateNames() {
    for (const player of this.players) {
      if (!player.club_player_id) continue;
      const clubPlayer = await ClubPlayer.query().find(player.club_player_id);
      if (!clubPlayer) continue;
      if (clubPlayer.name === player.name) continue;
      player.name = clubPlayer.name;
      await player.save();
    }
  }

  async pairPlayers(player1: Player, player2: Player) {
    for (const player of this.players) {
      if (player.id === player1.id) {
        player.partner_id = player2.id;
      } else if (player.id === player2.id) {
        player.partner_id = player1.id;
      } else if ([player1.id, player2.id].includes(player.partner_id)) {
        player.partner_id = undefined;
      }
    }

    await this.players.save();
  }

  async unpairPlayer(player: Player) {
    for (const p of this.players) {
      if (p.partner_id === player.id) {
        p.partner_id = undefined;
      }
    }
    player.partner_id = undefined;
    await this.players.save();
  }

  get warm_up_duration() {
    if (this.warm_up_started_at) {
      if (this.warm_up_stopped_at) {
        return Math.abs(dayjs(this.warm_up_stopped_at).diff(dayjs(this.warm_up_started_at)));
      } else {
        return Math.abs(dayjs().diff(dayjs(this.warm_up_started_at)));
      }
    } else {
      return 0;
    }
  }

  start_warm_up() {
    if (!this.warm_up_started_at) {
      this.warm_up_started_at = new Date();
    } else if (this.warm_up_stopped_at) {
      this.warm_up_started_at = new Date(new Date().valueOf() - this.warm_up_duration);
    }
    this.warm_up_stopped_at = undefined;
    return this.save();
  }

  stop_warm_up() {
    if (isPresent(this.warm_up_stopped_at)) return;

    this.warm_up_stopped_at = new Date();
    return this.save();
  }

  reset_warm_up() {
    this.warm_up_started_at = undefined;
    this.warm_up_stopped_at = undefined;
    return this.save();
  }

  get warm_up_running(): boolean {
    return Boolean(this.warm_up_started_at && !this.warm_up_stopped_at);
  }

  get warm_up_started(): boolean {
    return isPresent(this.warm_up_started_at);
  }

  get warm_up_finished(): boolean {
    return this.warm_up_started && isPresent(this.warm_up_stopped_at);
  }

  get warm_up_can_start(): boolean {
    return !this.warm_up_running && !this.game_sets.some((set) => set.started);
  }
}

export default Night;
