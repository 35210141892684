// Generated by mixitone-router, changes to this file will be overridden
/* eslint-disable */

import { components, hooks, utils } from "@mixitone/router";

export type Path =
  | `/`
  | `/account`
  | `/account/create`
  | `/account/credits`
  | `/app`
  | `/clubs/:clubId`
  | `/clubs/:clubId/groups`
  | `/clubs/:clubId/players`
  | `/clubs/:clubId/sessions`
  | `/create`
  | `/forgot_password`
  | `/invite/accept/:inviteToken`
  | `/login`
  | `/not_found`
  | `/profile`
  | `/reset_password`
  | `/signup`
  | `/t/:token`
  | `/t/:token/games`
  | `/t/:token/players`
  | `/t/:token/preview`
  | `/t/:token/token_association/:token_notification_id`;

export type Params = {
  "/clubs/:clubId": { clubId: string };
  "/clubs/:clubId/groups": { clubId: string };
  "/clubs/:clubId/players": { clubId: string };
  "/clubs/:clubId/sessions": { clubId: string };
  "/invite/accept/:inviteToken": { inviteToken: string };
  "/t/:token": { token: string };
  "/t/:token/games": { token: string };
  "/t/:token/players": { token: string };
  "/t/:token/preview": { token: string };
  "/t/:token/token_association/:token_notification_id": { token: string; token_notification_id: string };
};

export type ModalPath =
  | `/account/archived_clubs`
  | `/account/clubs/new`
  | `/account/invite`
  | `/account/users/:userId/permissions`
  | `/clubs/:clubId/edit`
  | `/clubs/:clubId/events/:eventId/edit`
  | `/clubs/:clubId/events/:recurringSessionId/list`
  | `/clubs/:clubId/events/new`
  | `/clubs/:clubId/groups/rules/:id/edit`
  | `/clubs/:clubId/groups/rules/new`
  | `/clubs/:clubId/players/:id/edit`
  | `/clubs/:clubId/players/new`
  | `/clubs/:clubId/sessions/new`
  | `/clubs/:clubId/stats/session/:id`
  | `/t/:token/:clubId/players/:id/edit`
  | `/t/:token/log`
  | `/t/:token/qr_scan`
  | `/t/:token/setup`
  | `/t/:token/stats`;

export type ModalParams = {
  "/account/users/:userId/permissions": { userId: string };
  "/clubs/:clubId/edit": { clubId: string };
  "/clubs/:clubId/events/:eventId/edit": { clubId: string; eventId: string };
  "/clubs/:clubId/events/:recurringSessionId/list": { clubId: string; recurringSessionId: string };
  "/clubs/:clubId/events/new": { clubId: string };
  "/clubs/:clubId/groups/rules/:id/edit": { clubId: string; id: string };
  "/clubs/:clubId/groups/rules/new": { clubId: string };
  "/clubs/:clubId/players/:id/edit": { clubId: string; id: string };
  "/clubs/:clubId/players/new": { clubId: string };
  "/clubs/:clubId/sessions/new": { clubId: string };
  "/clubs/:clubId/stats/session/:id": { clubId: string; id: string };
  "/t/:token/:clubId/players/:id/edit": { token: string; clubId: string; id: string };
  "/t/:token/log": { token: string };
  "/t/:token/qr_scan": { token: string };
  "/t/:token/setup": { token: string };
  "/t/:token/stats": { token: string };
};

export const { Link, Navigate } = components<Path, Params>();
export const { useNavigate, useParams, useModals, useModalParams } = hooks<
  Path,
  Params,
  ModalPath,
  ModalParams
>();
export const { redirect } = utils<Path, Params>();
